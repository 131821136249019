import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { Button } from "primereact/button";

const php = new Php();
const height = window.innerHeight;

export default function LedgerLogs(props) {
  const [loading, setLoading] = React.useState(false);
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_users_ledger();
  }, []);

  const load_users_ledger = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        c_id: props.data.id,
      };

      php.load_users_ledger(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientLedgerLogs(r.ledger_logs);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const delete_entry = (id) => {
    if (id <= 0) {
      addNotification("Invalid ledger entry. Please try again !!", "error");
    } else {
      if (window.confirm("Do you really want to delete this ledger entry?")) {
        console.log("hi");
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            g_id: localStorage.getItem("godsId"),
            e_id: id,
          };

          php.delete_ledger_entry(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_ledger();
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientLedgerLogs}
        size="small"
      >
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="remarks"
          header="Remarks"
        ></Column>

        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="type"
          body={(rowData) =>
            rowData.in_out == 0 ? (
              <div style={{ color: NewTheme.greencolor }}>Deposit</div>
            ) : (
              <div style={{ color: NewTheme.redcolor }}>Withdraw</div>
            )
          }
          header="In/Out"
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="amount"
          body={(rowData) =>
            rowData.amount >= 0 ? (
              <div style={{ color: NewTheme.greencolor }}>
                +{rowData.amount}
              </div>
            ) : (
              <div style={{ color: NewTheme.redcolor }}>{rowData.amount}</div>
            )
          }
          header="Amount"
        ></Column>
        <Column
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="type"
          body={(rowData) => (
            <div>
              {rowData.old_balance}
              <span
                style={{
                  color: NewTheme.MainColor,
                  fontWeight: "bolder",
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                {"  ->  "}
              </span>
              {rowData.new_balance}
            </div>
          )}
          header="Old->New Bal"
        ></Column>
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
        <Column
          field="date_created"
          showFilterMenu={false}
          header=""
          style={{ width: "5%" }}
          body={(rowData) => (
            <div>
              <Button
                type="button"
                icon="pi pi-times"
                severity="danger"
                style={{ height: 25, width: 25 }}
                onClick={() => delete_entry(rowData.id)}
                data-pr-tooltip="PDF"
              />
            </div>
          )}
        />
      </DataTable>
    </div>
  );
}
