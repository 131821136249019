import React from "react";
import styled from "styled-components";
import { NewTheme } from "../../Theme/Theme";

export const BalanceWrapper = styled.div`
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  justify-content: space-evenly; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
`;

export const BalanceInput = styled.input`
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  border: 0.5px solid lightgray;
`;

export const BalanceUpdateButton = styled.div`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function Deposit(props) {
  const [loading, setLoading] = React.useState(false);

  const handleLimit = () => {};
  return (
    <>
      <BalanceWrapper>
        <BalanceContainer>
          <BalanceLabel>Balance (+/-)</BalanceLabel>
          <BalanceInput type="number" placeholder="Enter In Number" />
        </BalanceContainer>
        <BalanceUpdateButton>UPDATE</BalanceUpdateButton>
      </BalanceWrapper>
      <BalanceWrapper>
        <BalanceContainer>
          <BalanceLabel>Multiplier (+/-)</BalanceLabel>
          <BalanceInput type="number" placeholder="Enter In Number" />
        </BalanceContainer>
        <BalanceUpdateButton>UPDATE</BalanceUpdateButton>
      </BalanceWrapper>
      <BalanceWrapper>
        <BalanceContainer>
          <BalanceLabel>Limit (+/-)</BalanceLabel>
          <BalanceInput type="number" placeholder="Enter In Number" />
        </BalanceContainer>
        <BalanceUpdateButton>UPDATE</BalanceUpdateButton>
      </BalanceWrapper>
    </>
  );
}
