import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React from "react";

export default function Filter(props) {
  return (
    <>
      {" "}
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 150,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.setSelectedAdmin(e.value);
            } else {
              props.setSelectedAdmin(e.value);
            }
          }}
          value={props.selectedAdmin}
          options={props.adminsFilter}
          optionLabel="username"
          placeholder="Admin"
          filter
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 150,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.setSelectedMaster(e.value);
            } else {
              props.setSelectedMaster(e.value);
            }
          }}
          value={props.selectedMaster}
          options={props.mastersFilter}
          optionLabel="username"
          placeholder="Master"
          filter
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <Dropdown
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 200,
            height: 30,
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              props.setSelectedUser(e.value);
            } else {
              props.setSelectedUser(e.value);
            }
          }}
          value={props.selectedUser}
          options={props.usersFilter}
          optionLabel="username"
          placeholder="Filter Username"
          filter
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Calendar
          dateFormat="dd/mm/yy"
          style={{ width: 210 }}
          selectionMode="range"
          id="calendar-12h"
          value={props.date}
          onChange={(e) => props.setDate(e.value)}
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-filter"
          severity="success"
          label="SUBMIT"
          style={{
            height: 25,
            width: 80,
            fontSize: 12,
            padding: 10,
          }}
          onClick={() => props.reload()}
          data-pr-tooltip="XLS"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-times"
          label="CLEAR"
          severity="danger"
          style={{
            height: 25,
            width: 80,
            fontSize: 12,
            padding: 10,
          }}
          onClick={() => {
            props.clearFilter();
          }}
          data-pr-tooltip="XLS"
        />
      </div>
    </>
  );
}
