import React, { useState } from "react";
import styled from "styled-components";

import "jspdf-autotable";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import CheckRatesModal from "./Modal/CheckRatesModal";
import UploadMcxRates from "./Modal/UploadMcxRates";
const php = new Php();

const height = window.innerHeight;

const TaskListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TaskItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #f8f8f8;
  border-radius: 5px;
`;

const TaskNumber = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const CreateClientButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #22c55e;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 30px;

  &:hover {
    background-color: #1e944a;
  }
`;
const CreateClientAnchor = styled.a`
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #22c55e;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 20px;

  &:hover {
    background-color: #1e944a;
  }
`;

export default function Closing() {
  const [transactionData, setTransactionData] = useState([]);
  const [positionData, setPositionData] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [closingStatus, setClosingStatus] = React.useState(false);

  const [stepOne, setStepOne] = React.useState(false);
  const [stepTwo, setStepTwo] = React.useState(false);
  const [stepThree, setStepThree] = React.useState(false);
  const [stepCheckRates, setStepCheckRates] = React.useState(false);
  const [firstBackUpLinkUser, setFirstBackUpLinkUser] = React.useState("");
  const [firstBackUpLinkPosition, setFirstBackUpLinkPosition] =
    React.useState("");
  const [secondBackUpLinkUser, setSecondBackUpLinkUser] = React.useState("");
  const [fullBackUpLinkUser, setFullBackUpLinkUser] = React.useState("");

  const [date, setDate] = useState([new Date(), new Date()]);

  React.useEffect(() => {
    get_closing_status();
  }, []);

  const get_closing_status = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.get_closing_status(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          if (r.closing == "1" && r.settlement == "1") {
            unlock_closing();
          } else {
            setClosingStatus(true);
          }
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const unlock_closing = () => {
    var selection = window.prompt("Enter Password To Unlock");
    if (selection == "") {
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          unlock: selection,
        };

        php.unlock_closing(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            setClosingStatus(true);
            window.alert(r.message);
          } else {
            window.alert(r.message);
          }
        });
      }
    }
  };

  const step_one = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.closing_step_one(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setFirstBackUpLinkUser(r.link);
          setStepOne(true);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const step_two = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.closing_step_two(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setFirstBackUpLinkPosition(r.link);
          setStepTwo(true);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const update_rates_position = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.update_rates_position(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          // setPositionData(r.position_data);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const run_settlement = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.run_settlement(data).then((r) => {
        console.log(r, "settlement");
        setLoading(false);
        if (r.error === "False") {
          // setPositionData(r.position_data);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const run_closing = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.run_closing(data).then((r) => {
        console.log(r, "closing");
        setLoading(false);
        if (r.error === "False") {
          // setPositionData(r.position_data);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const full_backup = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.full_backup(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setFullBackUpLinkUser(r.full_link);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const client_backup = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.client_backup(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setSecondBackUpLinkUser(r.link);
          window.open(r.link);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const step_three = () => {
    window.open(
      "https://testapi.dreamtrade.biz/GodsPhpApi/Closing/update_excel_rates.php"
    );
    setStepThree(true);
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        fontWeight: "bold",
      }}
    ></div>
  );

  const [visible, setVisible] = useState(false);
  const [visibleMcxModal, setVisibleMcxModal] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  const showMcxModal = (position) => {
    setPosition("top");
    setVisibleMcxModal(true);
  };

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Closing" cmp={filterbar} />

      {closingStatus == false ? (
        <div>Unlock Closing First</div>
      ) : (
        <div id={"stick"} style={{ position: "relative" }}>
          <TaskListWrapper>
            <TaskItem>
              <TaskNumber>1.</TaskNumber> Take Client Back Up
              <CreateClientButton onClick={() => step_one()}>
                Take Now
              </CreateClientButton>
            </TaskItem>
            <TaskItem>
              <TaskNumber>2.</TaskNumber> DOWNLOAD CLIENT BACKUP
              {stepOne && (
                <CreateClientAnchor target="_blank" href={firstBackUpLinkUser}>
                  CLICK HERE
                </CreateClientAnchor>
              )}
            </TaskItem>
            <TaskItem>
              <TaskNumber>4.</TaskNumber> Take Position Back Up
              {stepOne && (
                <CreateClientButton onClick={() => step_two()}>
                  Take Now
                </CreateClientButton>
              )}
            </TaskItem>
            <TaskItem>
              <TaskNumber>2.</TaskNumber> DOWNLOAD POSITION BACKUP
              {stepTwo && (
                <CreateClientAnchor
                  target="_blank"
                  href={firstBackUpLinkPosition}
                >
                  CLICK HERE
                </CreateClientAnchor>
              )}
            </TaskItem>

            <TaskItem>
              <TaskNumber>5.</TaskNumber> UPLOAD F&O NSE CSV ONLY
              {stepTwo && (
                <CreateClientButton onClick={() => step_three()}>
                  UPLOAD CSV FILE
                </CreateClientButton>
              )}
            </TaskItem>
            <TaskItem>
              <TaskNumber>6.</TaskNumber> UPDATE F&O NSE POSITIONS RATES
              {/* {stepThree && <CreateClientButton>Get Rates</CreateClientButton>} */}
              <CreateClientButton onClick={() => update_rates_position()}>
                UPDATE
              </CreateClientButton>
            </TaskItem>
            <TaskItem>
              <TaskNumber>7.</TaskNumber> UPLOAD MCX RATES
              {/* {stepThree && <CreateClientButton>Get Rates</CreateClientButton>} */}
              <CreateClientButton onClick={() => showMcxModal()}>
                UPDATE MCX
              </CreateClientButton>
            </TaskItem>
            <TaskItem>
              <TaskNumber>8.</TaskNumber> Check F&O / MCX Rates
              {/* {stepThree && <CreateClientButton>Get Rates</CreateClientButton>} */}
              <CreateClientButton onClick={() => show()}>
                LOAD RATES
              </CreateClientButton>
            </TaskItem>

            <TaskItem>
              <TaskNumber>9.</TaskNumber> Run Closing
              {/* {stepCheckRates && <CreateClientButton>Get Rates</CreateClientButton>} */}
              <CreateClientButton onClick={() => run_closing()}>
                {loading == true ? "LOADING...." : "  CLICK TO RUN"}
              </CreateClientButton>
            </TaskItem>

            <TaskItem>
              <TaskNumber>10.</TaskNumber> TAKE CLIENT BACKUP
              {/* {stepCheckRates && <CreateClientButton>Get Rates</CreateClientButton>} */}
              <CreateClientButton onClick={() => client_backup()}>
                DOWNLOAD
              </CreateClientButton>
            </TaskItem>

            <TaskItem>
              <TaskNumber>11.</TaskNumber> RUN SETTLEMENT
              {/* {stepCheckRates && <CreateClientButton>Get Rates</CreateClientButton>} */}
              <CreateClientButton onClick={() => run_settlement()}>
                {loading == true ? "LOADING...." : "  CLICK TO RUN"}
              </CreateClientButton>
            </TaskItem>
            <TaskItem>
              <TaskNumber>12.</TaskNumber> TAKE FULLBACKUP
              {/* {stepCheckRates && <CreateClientButton>Get Rates</CreateClientButton>} */}
              <CreateClientButton onClick={() => full_backup()}>
                TAKE
              </CreateClientButton>
            </TaskItem>
            {/* <TaskItem>
              <TaskNumber>12.</TaskNumber> DOWNLOAD FULLBACKUP
              {fullBackUpLinkUser == "" ? null : (
                <CreateClientAnchor target="_blank" href={fullBackUpLinkUser}>
                  DOWNLOAD
                </CreateClientAnchor>
              )}
            </TaskItem> */}
          </TaskListWrapper>
        </div>
      )}
      {visible && (
        <CheckRatesModal
          setVisible={() => setVisible(false)}
          visible={visible}
        />
      )}
      {visibleMcxModal && (
        <UploadMcxRates
          setVisible={() => setVisibleMcxModal(false)}
          visible={visibleMcxModal}
        />
      )}
    </div>
  );
}
