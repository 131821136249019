import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { BalanceInput, BalanceLabel } from "../../Users/Deposit/Deposit";
import { ButtonGrid, CreateClientButton, FlexItem } from "./Styled";

const php = new Php();

export default function SegmentPendingModal(props) {
  const [formData, setFormData] = React.useState();

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  function handleButtonClick(key) {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.admin_id,
        button_key: key,
      };

      php.change_admins_setting(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          props.load_admin_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  }

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      {/* <Button
        label="Update"
        icon="pi pi-check"
        onClick={() => handleAdminMargin()}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      /> */}
    </div>
  );

  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>Edit Segment Pending </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "75vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <ButtonGrid>
        {props.buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
            onClick={() => handleButtonClick(button.id)} // Add the onClick handler
          >
            {button.text}
          </CreateClientButton>
        ))}
      </ButtonGrid>
    </Dialog>
  );
}
