import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import { InputText } from "primereact/inputtext";
import { CreateClientButton } from "../Admin/Overview/Styled";
const php = new Php();

const height = window.innerHeight;

export default function Scripts() {
  const [scripts, setScripts] = useState([]);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  useEffect(() => {
    get_all_scripts();
  }, []);

  const get_all_scripts = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
    };

    php.get_all_scripts(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setScripts(r.script);
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          gap: 10,
        }}
      >
        {/* <CreateClientButton
          style={{ height: 30 }}
          active={true}
          color={NewTheme.MainColor}
          // onClick={() => props.get_four_segment()}
        >
          {"International"}
        </CreateClientButton> */}
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
    </div>
  );

  const handleBan = (rowData) => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      symbol: rowData.symbol,
      script_type: rowData.script_type,
      script_id: rowData.script_id,
    };

    php.ban_script(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        get_all_scripts();
        addNotification(r.message, "success");
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Scripts" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={scripts}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginator
          dataKey="id"
          rows={15}
          globalFilterFields={["symbol", "symbol_display"]}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column
            field="symbol_display"
            showFilterMenu={false}
            header="Symbol Display"
            sortable
            body={(rowData) => (
              <div>{rowData?.symbol_display?.toUpperCase()}</div>
            )}
          />
          <Column
            field="symbol"
            showFilterMenu={false}
            header="Symbol"
            sortable
            body={(rowData) => <div>{rowData?.symbol?.toUpperCase()}</div>}
          />
          <Column
            field="segment"
            showFilterMenu={false}
            header="Segment"
            sortable
            body={(rowData) => <div>{rowData?.script_type?.toUpperCase()}</div>}
          />
          <Column
            field="Min Qty"
            showFilterMenu={false}
            header="Min Qty"
            body={(rowData) => <div>{rowData?.min_qty}</div>}
          />

          <Column
            field="Punch Qty"
            showFilterMenu={false}
            header="Punch Qty"
            body={(rowData) => <div>{rowData?.punch_qty}</div>}
          />
          <Column
            field="Total Qty"
            showFilterMenu={false}
            header="Total Qty"
            body={(rowData) => <div>{rowData?.total_qty}</div>}
          />

          <Column
            field="expiry_date"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Expiry"
            sortable
            body={(rowData) => (
              <div>
                {moment(rowData.expiry_date).format("DD-MMM-YY ")}
                <br />
                {/* <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.expiry_date).format("HH:mm:ss")}
                </span> */}
              </div>
            )}
          />

          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Unban/Ban"
            body={(rowData) => (
              <div style={{ display: "flex" }}>
                <Button
                  type="button"
                  label={rowData.only_sq == 0 ? "Ban" : "Unban"}
                  severity={rowData.only_sq == 0 ? "danger" : "success"}
                  style={{ height: 25, fontSize: 12 }}
                  onClick={() => handleBan(rowData)}
                />
              </div>
            )}
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
}
