import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();
const height = window.innerHeight;

export default function LoginLogs(props) {
  const [loading, setLoading] = React.useState(false);
  const [clientRejectionLogs, setClientRejectionLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_admins_login();
  }, []);

  const load_admins_login = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.id,
      };

      php.load_admins_login(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientRejectionLogs(r.login_logs);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientRejectionLogs}
        size="small"
      >
        <Column
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="remarks"
          header="Devices"
        ></Column>
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="version"
          header="Version"
        ></Column>
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="ip"
          header="IP"
        ></Column>
        <Column
          style={{ width: "40%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
      </DataTable>
    </div>
  );
}
