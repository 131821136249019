import React from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export const BalanceUpdateButton = styled.div`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function AddMaster(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [superBrokearge, setSuperBrokearge] = React.useState([]);

  const [error, setError] = React.useState("");
  const [name, setName] = React.useState("");
  const [username, setUserame] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [margin, setMargin] = React.useState(0);

  const [maxFutBrokerage, setMaxFutBrokerage] = React.useState("");
  const [minFutBrokerage, setMinFutBrokerage] = React.useState("");
  const [maxFoBrokerage, setMaxFoBrokerage] = React.useState("");
  const [minFoBrokerage, setMinFoBrokerage] = React.useState("");
  const [maxMcxBrokerage, setMaxMcxBrokerage] = React.useState("");
  const [minMcxBrokerage, setMinMcxBrokerage] = React.useState("");
  const [maxMcxCrBrokerage, setMaxMcxCrBrokerage] = React.useState("");
  const [minMcxCrBrokerage, setMinMcxCrBrokerage] = React.useState("");

  const [sharingPercent, setSharingPercent] = React.useState(0);

  const superMargin = JSON.parse(localStorage.getItem("supersDetails")).margin;

  React.useEffect(() => {
    load_supers_brokerages();
  }, []);

  const load_supers_brokerages = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        m_id: props.master_id,
      };

      php.load_supers_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setSuperBrokearge(r.supers_brokerage);
          setMaxFutBrokerage(r.supers_brokerage.max_fut_brokerage);
          setMinFutBrokerage(r.supers_brokerage.min_fut_brokerage);
          setMaxFoBrokerage(r.supers_brokerage.max_fo_brokerage);
          setMinFoBrokerage(r.supers_brokerage.min_fo_brokerage);
          setMaxMcxBrokerage(r.supers_brokerage.max_mcx_brokerage);
          setMinMcxBrokerage(r.supers_brokerage.min_mcx_brokerage);
          setMaxMcxCrBrokerage(r.supers_brokerage.max_cr_mcx_brokerage);
          setMinMcxCrBrokerage(r.supers_brokerage.min_cr_mcx_brokerage);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const add_master = (e) => {
    setError("");
    if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (username == "" || username.length > 14 || username.length < 2) {
      addNotification("Please Enter Username Upto 14 Character", "error");
    } else if (password == "" || password.length < 3) {
      addNotification(
        "Please Enter Password with atleast 3 character",
        "error"
      );
      // } else if (
      //   parseFloat(minFutBrokerage) <
      //     parseFloat(superBrokearge.min_fut_brokerage) ||
      //   parseFloat(minFutBrokerage) > parseFloat(superBrokearge.max_fut_brokerage)
      // ) {
      //   setError("Min Future Brokerage");
      //   addNotification(
      //     "Enter Min Future Brokerage Between " +
      //       superBrokearge.min_fut_brokerage +
      //       " & " +
      //       superBrokearge.max_fut_brokerage,
      //     "error"
      //   );
      // } else if (
      //   parseFloat(maxFutBrokerage) <
      //     parseFloat(superBrokearge.min_fut_brokerage) ||
      //   parseFloat(maxFutBrokerage) > parseFloat(superBrokearge.max_fut_brokerage)
      // ) {
      //   setError("Max Future Brokerage");
      //   addNotification(
      //     "Enter Max Future Brokerage Between " +
      //       superBrokearge.min_fut_brokerage +
      //       " & " +
      //       superBrokearge.max_fut_brokerage,
      //     "error"
      //   );
      // } else if (parseFloat(maxFutBrokerage) < parseFloat(minFutBrokerage)) {
      //   setError("Max Future Brokerage");
      //   addNotification(
      //     "Enter Max Future Brokerage More Than " + minFutBrokerage,
      //     "error"
      //   );
      // } else if (
      //   ///mcx
      //   parseFloat(minMcxBrokerage) <
      //     parseFloat(superBrokearge.min_mcx_brokerage) ||
      //   parseFloat(minMcxBrokerage) > parseFloat(superBrokearge.max_mcx_brokerage)
      // ) {
      //   setError("Min Mcx Brokerage");
      //   addNotification(
      //     "Enter Min Mcx Brokerage Between " +
      //       superBrokearge.min_mcx_brokerage +
      //       " & " +
      //       superBrokearge.max_mcx_brokerage,
      //     "error"
      //   );
      // } else if (
      //   parseFloat(maxMcxBrokerage) <
      //     parseFloat(superBrokearge.min_mcx_brokerage) ||
      //   parseFloat(maxMcxBrokerage) > parseFloat(superBrokearge.max_mcx_brokerage)
      // ) {
      //   setError("Max Mcx Brokerage");
      //   addNotification(
      //     "Enter Max Mcx Brokerage Between " +
      //       superBrokearge.min_mcx_brokerage +
      //       " & " +
      //       superBrokearge.max_mcx_brokerage,
      //     "error"
      //   );
      // } else if (parseFloat(maxMcxBrokerage) < parseFloat(minMcxBrokerage)) {
      //   setError("Max Mcx Brokerage");
      //   addNotification(
      //     "Enter Max Mcx Brokerage More Than " + minMcxBrokerage,
      //     "error"
      //   );
      // } else if (
      //   ///mcx cr wise
      //   parseFloat(minMcxCrBrokerage) <
      //     parseFloat(superBrokearge.min_cr_mcx_brokerage) ||
      //   parseFloat(minMcxCrBrokerage) >
      //     parseFloat(superBrokearge.max_cr_mcx_brokerage)
      // ) {
      //   setError("Min Mcx Brokerage Cr");
      //   addNotification(
      //     "Enter Min Mcx Brokerage (Cr) Between " +
      //       superBrokearge.min_cr_mcx_brokerage +
      //       " & " +
      //       superBrokearge.max_cr_mcx_brokerage,
      //     "error"
      //   );
      // } else if (
      //   parseFloat(maxMcxCrBrokerage) <
      //     parseFloat(superBrokearge.min_cr_mcx_brokerage) ||
      //   parseFloat(maxMcxCrBrokerage) >
      //     parseFloat(superBrokearge.max_cr_mcx_brokerage)
      // ) {
      //   setError("Max Mcx Brokerage Cr");
      //   addNotification(
      //     "Enter Max Mcx Brokerage (Cr) Between " +
      //       superBrokearge.min_cr_mcx_brokerage +
      //       " & " +
      //       superBrokearge.max_cr_mcx_brokerage,
      //     "error"
      //   );
      // } else if (parseFloat(maxMcxCrBrokerage) < parseFloat(minMcxCrBrokerage)) {
      //   setError("Max Mcx Brokerage Cr");
      //   addNotification(
      //     "Enter Max Mcx Brokerage (Cr) More Than " + minMcxCrBrokerage,
      //     "error"
      //   );
      // } else if (
      //   ///options
      //   parseFloat(minFoBrokerage) <
      //     parseFloat(superBrokearge.min_fo_brokerage) ||
      //   parseFloat(minFoBrokerage) > parseFloat(superBrokearge.max_fo_brokerage)
      // ) {
      //   setError("Min Option Brokerage");
      //   addNotification(
      //     "Enter Min Option Brokerage Between " +
      //       superBrokearge.min_fo_brokerage +
      //       " & " +
      //       superBrokearge.max_fo_brokerage,
      //     "error"
      //   );
      // } else if (
      //   parseFloat(maxFoBrokerage) <
      //     parseFloat(superBrokearge.min_fo_brokerage) ||
      //   parseFloat(maxFoBrokerage) > parseFloat(superBrokearge.max_fo_brokerage)
      // ) {
      //   setError("Max Option Brokerage");
      //   addNotification(
      //     "Enter Max Option Brokerage Between " +
      //       superBrokearge.min_fo_brokerage +
      //       " & " +
      //       superBrokearge.max_fo_brokerage,
      //     "error"
      //   );
      // } else if (parseFloat(maxFoBrokerage) < parseFloat(minFoBrokerage)) {
      //   setError("Max Option Brokerage");
      //   addNotification(
      //     "Enter Max Option Brokerage More Than " + minFoBrokerage,
      //     "error"
      //   );
    } else if (margin < 0 || margin > 100) {
      addNotification("Please Enter Margin Between 0 to 100 ", "error");
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          s_id: localStorage.getItem("supersId"),
          name: name,
          username: username,
          password: password,
          margin: margin,
        };

        php.add_master(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            props.reload();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const calcSharing = (value) => {
    if (parseFloat(value) > parseFloat(superMargin) && superMargin != 0) {
      setSharingPercent("");
      alert.error("Sharing Must Be Between 0 To " + superMargin);
      // } else if (sharing < 1 && value > 0) {
      //   setSharing(0);
      //   alert.error("Sharing Must Be Between 0 To " + sharing);
    } else {
      setSharingPercent(value);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      {/* <Patti /> */}
      <Card
        title="Add Master"
        style={{
          margin: 10,
          position: "relative",
          height: "calc(100% - (60px - 1px))",
          overflowY: "scroll",
        }}
      >
        <CreateClientButton
          active={false}
          color={NewTheme.redcolor}
          onClick={() => props.close()}
          style={{ position: "absolute", top: 10, right: 5 }}
        >
          Close
        </CreateClientButton>
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Name </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Enter Name "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Username </BalanceLabel>
              <BalanceInput
                type="text"
                placeholder="Enter Username 2 to 14 Character "
                onChange={(e) => setUserame(e.target.value)}
                value={username}
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Password</BalanceLabel>
              <BalanceInput
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password with atleast 3 character"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Margin</BalanceLabel>
              <BalanceInput
                type="number"
                value={margin}
                onChange={(e) => setMargin(e.target.value)}
                placeholder="Enter "
              />
            </BalanceContainer>
            {/* <BalanceContainer>
              <BalanceLabel>Min Future Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_fut_brokerage}</label>
                </span>
                <InputText
                  value={minFutBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Future Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinFutBrokerage(e.target.value)}
                  placeholder="Enter Min Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{superBrokearge.max_fut_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Future Brokerage</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_fut_brokerage}</label>
                </span>
                <InputText
                  value={maxFutBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Future Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxFutBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.max_fut_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min Mcx Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_mcx_brokerage}</label>
                </span>
                <InputText
                  value={minMcxBrokerage}
                  type="number"
                  style={{
                    border: error == "Min Mcx Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinMcxBrokerage(e.target.value)}
                  placeholder="Enter Min Mcx Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.max_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Mcx Brokerage</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_mcx_brokerage}</label>
                </span>
                <InputText
                  value={maxMcxBrokerage}
                  type="number"
                  style={{
                    border: error == "Max Mcx Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxMcxBrokerage(e.target.value)}
                  placeholder="Enter Max Mcx Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.max_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min Mcx Brokerage (Cr)</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_cr_mcx_brokerage}</label>
                </span>
                <InputText
                  value={minMcxCrBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Mcx Brokerage Cr" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinMcxCrBrokerage(e.target.value)}
                  placeholder="Enter Min Mcx Brokerage Cr"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.max_cr_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Mcx Brokerage (Cr)</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_cr_mcx_brokerage}</label>
                </span>
                <InputText
                  value={maxMcxCrBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Mcx Brokerage Cr" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxMcxCrBrokerage(e.target.value)}
                  placeholder="Enter Max Mcx Brokerage Cr"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.max_cr_mcx_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min Option Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_fo_brokerage}</label>
                </span>
                <InputText
                  value={minFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinFoBrokerage(e.target.value)}
                  placeholder="Enter Min Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.max_fo_brokerage}</label>
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Max Option Brokerage</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label>{superBrokearge.min_fo_brokerage}</label>
                </span>
                <InputText
                  value={maxFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxFoBrokerage(e.target.value)}
                  placeholder="Enter Max Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label>{superBrokearge.max_fo_brokerage}</label>
                </span>
              </div>
            </BalanceContainer> */}
          </BalanceWrapper>
          <BalanceUpdateButton onClick={add_master}>Save</BalanceUpdateButton>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
