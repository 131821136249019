import styled from "styled-components";
import { NewTheme } from "../Theme/Theme";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, ${NewTheme.MainColor}, #ffffff);
`;

const Logo = styled.img`
  height: 150px;
  margin-bottom: 20px;
  border-radius: 10px;
`;

const LoginForm = styled.form`
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:hover {
    border-color: ${NewTheme.MainColor};
  }
  &:focus {
    outline: none; /* Remove the default focus outline */
    border-color: ${NewTheme.MainColor}; /* Apply your theme color on focus */
    box-shadow: 0 0 5px ${NewTheme.MainColor}; /* Add a box shadow on focus for better visibility */
  }
`;

const SmallButton = styled.button`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background: ${NewTheme.MainColor};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;

  &:hover {
    background: ${NewTheme.greencolor};
  }
`;

export { SmallButton, Input, LoginContainer, LoginForm, Logo };
