import React from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { faL } from "@fortawesome/free-solid-svg-icons";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const BalanceUpdateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}

  &:focus {
    background: none;
    color: ${(props) => (props.color ? props.color : "#3959ab")};
    border: 1px solid ${(props) => (props.color ? props.color : "#3959ab")};
  }
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  width: 30%;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function Brokerage(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [godBrokerage, setGodBrokerage] = React.useState([]);
  const [adminBrokerage, setAdminBrokerage] = React.useState({});

  const [error, setError] = React.useState("");

  const [maxFutBrokerage, setMaxFutBrokerage] = React.useState("");
  const [minFutBrokerage, setMinFutBrokerage] = React.useState("");
  const [maxFoBrokerage, setMaxFoBrokerage] = React.useState("");
  const [minFoBrokerage, setMinFoBrokerage] = React.useState("");
  const [maxMcxBrokerage, setMaxMcxBrokerage] = React.useState("");
  const [minMcxBrokerage, setMinMcxBrokerage] = React.useState("");
  const [maxMcxCrBrokerage, setMaxMcxCrBrokerage] = React.useState("");
  const [minMcxCrBrokerage, setMinMcxCrBrokerage] = React.useState("");
  const [maxForexBrokerage, setMaxForexBrokerage] = React.useState("");
  const [minForexBrokerage, setMinForexBrokerage] = React.useState("");
  const [maxCryptoBrokerage, setMaxCryptoBrokerage] = React.useState("");
  const [minCryptoBrokerage, setMinCryptoBrokerage] = React.useState("");
  const [maxStocksBrokerage, setMaxStocksBrokerage] = React.useState("");
  const [minStocksBrokerage, setMinStocksBrokerage] = React.useState("");

  React.useEffect(() => {
    load_admin_brokerages();
    load_god_brokerages();
  }, []);

  const load_god_brokerages = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.id,
      };

      php.load_god_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setGodBrokerage(r.gods_brokerage);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const load_admin_brokerages = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.id,
      };

      php.load_admins_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setAdminBrokerage(r.admins_brokerage);
          setMaxFutBrokerage(r.admins_brokerage.max_fut_brokerage);
          setMinFutBrokerage(r.admins_brokerage.min_fut_brokerage);
          setMaxFoBrokerage(r.admins_brokerage.max_fo_brokerage);
          setMinFoBrokerage(r.admins_brokerage.min_fo_brokerage);
          setMaxMcxBrokerage(r.admins_brokerage.max_mcx_brokerage);
          setMinMcxBrokerage(r.admins_brokerage.min_mcx_brokerage);
          setMaxMcxCrBrokerage(r.admins_brokerage.max_cr_mcx_brokerage);
          setMinMcxCrBrokerage(r.admins_brokerage.min_cr_mcx_brokerage);

          setMaxForexBrokerage(r.admins_brokerage.max_forex_brokerage);
          setMinForexBrokerage(r.admins_brokerage.min_forex_brokerage);
          setMaxCryptoBrokerage(r.admins_brokerage.max_crypto_brokerage);
          setMinCryptoBrokerage(r.admins_brokerage.min_crypto_brokerage);
          setMaxStocksBrokerage(r.admins_brokerage.max_stocks_brokerage);
          setMinStocksBrokerage(r.admins_brokerage.min_stocks_brokerage);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const edit_super_brokerage = (e) => {
    setError("");
    if (
      parseFloat(minFutBrokerage) <
        parseFloat(godBrokerage.min_fut_brokerage) ||
      parseFloat(minFutBrokerage) > parseFloat(godBrokerage.max_fut_brokerage)
    ) {
      setError("Min Future Brokerage");
      addNotification(
        "Enter Min Future Brokerage Between " +
          godBrokerage.min_fut_brokerage +
          " & " +
          godBrokerage.max_fut_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxFutBrokerage) <
        parseFloat(godBrokerage.min_fut_brokerage) ||
      parseFloat(maxFutBrokerage) > parseFloat(godBrokerage.max_fut_brokerage)
    ) {
      setError("Max Future Brokerage");
      addNotification(
        "Enter Max Future Brokerage Between " +
          godBrokerage.min_fut_brokerage +
          " & " +
          godBrokerage.max_fut_brokerage,
        "error"
      );
    } else if (parseFloat(maxFutBrokerage) < parseFloat(minFutBrokerage)) {
      setError("Max Future Brokerage");
      addNotification(
        "Enter Max Future Brokerage More Than " + minFutBrokerage,
        "error"
      );
    } else if (
      ///mcx
      parseFloat(minMcxBrokerage) <
        parseFloat(godBrokerage.min_mcx_brokerage) ||
      parseFloat(minMcxBrokerage) > parseFloat(godBrokerage.max_mcx_brokerage)
    ) {
      setError("Min Mcx Brokerage");
      addNotification(
        "Enter Min Mcx Brokerage Between " +
          godBrokerage.min_mcx_brokerage +
          " & " +
          godBrokerage.max_mcx_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxMcxBrokerage) <
        parseFloat(godBrokerage.min_mcx_brokerage) ||
      parseFloat(maxMcxBrokerage) > parseFloat(godBrokerage.max_mcx_brokerage)
    ) {
      setError("Max Mcx Brokerage");
      addNotification(
        "Enter Max Mcx Brokerage Between " +
          godBrokerage.min_mcx_brokerage +
          " & " +
          godBrokerage.max_mcx_brokerage,
        "error"
      );
    } else if (parseFloat(maxMcxBrokerage) < parseFloat(minMcxBrokerage)) {
      setError("Max Mcx Brokerage");
      addNotification(
        "Enter Max Mcx Brokerage More Than " + minMcxBrokerage,
        "error"
      );
    } else if (
      ///mcx cr wise
      parseFloat(minMcxCrBrokerage) <
        parseFloat(godBrokerage.min_cr_mcx_brokerage) ||
      parseFloat(minMcxCrBrokerage) >
        parseFloat(godBrokerage.max_cr_mcx_brokerage)
    ) {
      setError("Min Mcx Brokerage Cr");
      addNotification(
        "Enter Min Mcx Brokerage (Cr) Between " +
          godBrokerage.min_cr_mcx_brokerage +
          " & " +
          godBrokerage.max_cr_mcx_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxMcxCrBrokerage) <
        parseFloat(godBrokerage.min_cr_mcx_brokerage) ||
      parseFloat(maxMcxCrBrokerage) >
        parseFloat(godBrokerage.max_cr_mcx_brokerage)
    ) {
      setError("Max Mcx Brokerage Cr");
      addNotification(
        "Enter Max Mcx Brokerage (Cr) Between " +
          godBrokerage.min_cr_mcx_brokerage +
          " & " +
          godBrokerage.max_cr_mcx_brokerage,
        "error"
      );
    } else if (parseFloat(maxMcxCrBrokerage) < parseFloat(minMcxCrBrokerage)) {
      setError("Max Mcx Brokerage Cr");
      addNotification(
        "Enter Max Mcx Brokerage (Cr) More Than " + minMcxCrBrokerage,
        "error"
      );
    } else if (
      ///options
      parseFloat(minFoBrokerage) < parseFloat(godBrokerage.min_fo_brokerage) ||
      parseFloat(minFoBrokerage) > parseFloat(godBrokerage.max_fo_brokerage)
    ) {
      setError("Min Option Brokerage");
      addNotification(
        "Enter Min Option Brokerage Between " +
          godBrokerage.min_fo_brokerage +
          " & " +
          godBrokerage.max_fo_brokerage,
        "error"
      );
    } else if (
      parseFloat(maxFoBrokerage) < parseFloat(godBrokerage.min_fo_brokerage) ||
      parseFloat(maxFoBrokerage) > parseFloat(godBrokerage.max_fo_brokerage)
    ) {
      setError("Max Option Brokerage");
      addNotification(
        "Enter Max Option Brokerage Between " +
          godBrokerage.min_fo_brokerage +
          " & " +
          godBrokerage.max_fo_brokerage,
        "error"
      );
    } else if (parseFloat(maxFoBrokerage) < parseFloat(minFoBrokerage)) {
      setError("Max Option Brokerage");
      addNotification(
        "Enter Max Option Brokerage More Than " + minFoBrokerage,
        "error"
      );
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          a_id: props.data.id,
          max_fut_brokerage: parseFloat(maxFutBrokerage).toFixed(3),
          min_fut_brokerage: parseFloat(minFutBrokerage).toFixed(3),
          max_fo_brokerage: maxFoBrokerage,
          min_fo_brokerage: minFoBrokerage,
          max_mcx_brokerage: maxMcxBrokerage,
          min_mcx_brokerage: minMcxBrokerage,
          max_mcx_cr_brokerage: maxMcxCrBrokerage,
          min_mcx_cr_brokerage: minMcxCrBrokerage,
          max_forex_brokerage: maxForexBrokerage,
          min_forex_brokerage: minForexBrokerage,
          max_crypto_brokerage: maxCryptoBrokerage,
          min_crypto_brokerage: minCryptoBrokerage,
          max_stocks_brokerage: maxStocksBrokerage,
          min_stocks_brokerage: minStocksBrokerage,
        };

        php.edit_admin_brokerage(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            load_admin_brokerages();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const BrokerageInput = ({ label, value, error, onChange, placeholder }) => {
    return (
      <div
        className="p-inputgroup flex-1"
        style={{ height: 40, width: "30%", minWidth: "2rem" }}
      >
        <span
          className="p-inputgroup-addon"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label style={{ fontSize: 12 }}>Min</label>
          <label style={{ fontSize: 12 }}>{label}</label>
        </span>
        <InputText
          value={value}
          type="number"
          style={{ border: error ? "1px solid red" : "" }}
          onChange={onChange}
          placeholder={placeholder}
        />
        <span
          className="p-inputgroup-addon"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label style={{ fontSize: 12 }}>Max</label>
          <label style={{ fontSize: 12 }}>{label}</label>
        </span>
      </div>
    );
  };

  const BrokerageContainer = ({
    label,
    min,
    max,
    value,
    error,
    onChange,
    placeholder,
  }) => {
    return (
      <BalanceContainer>
        <BalanceLabel>{label}</BalanceLabel>
        <BrokerageInput
          label={min}
          value={value}
          error={error}
          onChange={onChange}
          placeholder={placeholder}
        />
        <BrokerageInput
          label={max}
          value={value}
          error={error}
          onChange={onChange}
          placeholder={placeholder}
        />
      </BalanceContainer>
    );
  };

  const [brokerageTypes] = React.useState([
    { type: "Future", label: "Min/Max Future Brokerage" },
    { type: "Mcx", label: "Min/Max Mcx Brokerage" },
    { type: "McxCr", label: "Min/Max Mcx Brokerage (Cr)" },
    { type: "Option", label: "Min/Max Option Brokerage" },
    { type: "Forex", label: "Min/Max Forex Brokerage" },
    { type: "Crypto", label: "Min/Max Crypto Brokerage" },
    { type: "USStocks", label: "Min/Max US.Stocks Brokerage" },
  ]);

  const handleChange = (e, key) => {
    const { value } = e.target;
    setAdminBrokerage((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const renderBalanceContainers = () => {
    return brokerageTypes.map(({ type, label }) => (
      <BalanceContainer key={type}>
        <BalanceLabel>{label}</BalanceLabel>
        <div
          className="p-inputgroup flex-1"
          style={{ height: 40, width: "30%" }}
        >
          <span className="p-inputgroup-addon">
            <label style={{ fontSize: 12 }}>Min</label>
            <label style={{ fontSize: 12 }}>
              {adminBrokerage[`min_${type.toLowerCase()}_brokerage`]}
            </label>
          </span>
          <InputText
            value={adminBrokerage[`min${type}Brokerage`]}
            type="number"
            style={{
              border: error === `Min ${type} Brokerage` ? "1px solid red" : "",
            }}
            onChange={(e) =>
              handleChange(e, `min_${type.toLowerCase()}_brokerage`)
            }
            placeholder={`Enter Min ${type} Brokerage`}
          />
          <span className="p-inputgroup-addon">
            <label style={{ fontSize: 12 }}>Max</label>
            <label style={{ fontSize: 12 }}>
              {adminBrokerage[`max_${type.toLowerCase()}_brokerage`]}
            </label>
          </span>
        </div>
      </BalanceContainer>
    ));
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      {/* <Patti /> */}
      <Card
        style={{
          margin: 10,
          position: "relative",
          height: "calc(100% - (60px - 1px))",
          overflowY: "scroll",
        }}
      >
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel>Min/Max Future Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "2rem",
                  }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.min_fut_brokerage)}
                  </label>
                </span>
                <InputText
                  value={minFutBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Future Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinFutBrokerage(e.target.value)}
                  placeholder="Enter Min Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.max_fut_brokerage)}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.min_fut_brokerage)}
                  </label>
                </span>
                <InputText
                  value={maxFutBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Future Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxFutBrokerage(e.target.value)}
                  placeholder="Enter Max Future Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.max_fut_brokerage)}
                  </label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min/Max Mcx Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_mcx_brokerage}
                  </label>
                </span>
                <InputText
                  value={minMcxBrokerage}
                  type="number"
                  style={{
                    border: error == "Min Mcx Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinMcxBrokerage(e.target.value)}
                  placeholder="Enter Min Mcx Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_mcx_brokerage}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "2rem",
                  }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_mcx_brokerage}
                  </label>
                </span>
                <InputText
                  value={maxMcxBrokerage}
                  type="number"
                  style={{
                    border: error == "Max Mcx Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxMcxBrokerage(e.target.value)}
                  placeholder="Enter Max Mcx Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_mcx_brokerage}
                  </label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min/Max Mcx Brokerage (Cr)</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.min_cr_mcx_brokerage)}
                  </label>
                </span>
                <InputText
                  value={minMcxCrBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Mcx Brokerage Cr" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinMcxCrBrokerage(e.target.value)}
                  placeholder="Enter Min Mcx Brokerage Cr"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.max_cr_mcx_brokerage)}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.min_cr_mcx_brokerage)}
                  </label>
                </span>
                <InputText
                  value={maxMcxCrBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Mcx Brokerage Cr" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxMcxCrBrokerage(e.target.value)}
                  placeholder="Enter Max Mcx Brokerage Cr"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {parseFloat(godBrokerage.max_cr_mcx_brokerage)}
                  </label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min/Max Option Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_fo_brokerage}
                  </label>
                </span>
                <InputText
                  value={minFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinFoBrokerage(e.target.value)}
                  placeholder="Enter Min Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_fo_brokerage}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_fo_brokerage}
                  </label>
                </span>
                <InputText
                  value={maxFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxFoBrokerage(e.target.value)}
                  placeholder="Enter Max Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_fo_brokerage}
                  </label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min/Max Forex Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_forex_brokerage}
                  </label>
                </span>
                <InputText
                  value={minForexBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Frox Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinForexBrokerage(e.target.value)}
                  placeholder="Enter Min Frox Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_forex_brokerage}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_forex_brokerage}
                  </label>
                </span>
                <InputText
                  value={maxForexBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Forex Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxForexBrokerage(e.target.value)}
                  placeholder="Enter Max Forex Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_forex_brokerage}
                  </label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min/Max Crypto Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_crypto_brokerage}
                  </label>
                </span>
                <InputText
                  value={minCryptoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Crypto Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinCryptoBrokerage(e.target.value)}
                  placeholder="Enter Min Crypto Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_crypto_brokerage}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_crypto_brokerage}
                  </label>
                </span>
                <InputText
                  value={maxCryptoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Crypto Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxCryptoBrokerage(e.target.value)}
                  placeholder="Enter Max Crypto Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_crypto_brokerage}
                  </label>
                </span>
              </div>
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Min/Max US.Stocks Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_usstocks_brokerage}
                  </label>
                </span>
                <InputText
                  value={minStocksBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Usstocks Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinStocksBrokerage(e.target.value)}
                  placeholder="Enter Min Usstocks Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_usstocks_brokerage}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_usstocks_brokerage}
                  </label>
                </span>
                <InputText
                  value={maxStocksBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxStocksBrokerage(e.target.value)}
                  placeholder="Enter Max Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_usstocks_brokerage}
                  </label>
                </span>
              </div>
            </BalanceContainer>

            {/* <BalanceContainer>
              <BalanceLabel>Min/Max Comex Brokerage</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_fo_brokerage}
                  </label>
                </span>
                <InputText
                  value={minFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Min Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMinFoBrokerage(e.target.value)}
                  placeholder="Enter Min Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_fo_brokerage}
                  </label>
                </span>
              </div>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "30%", minWidth: "2rem" }}
              >
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Min "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.min_fo_brokerage}
                  </label>
                </span>
                <InputText
                  value={maxFoBrokerage}
                  type="number"
                  style={{
                    border:
                      error == "Max Option Brokerage" ? "1px solid red" : "",
                  }}
                  onChange={(e) => setMaxFoBrokerage(e.target.value)}
                  placeholder="Enter Max Option Brokerage"
                />
                <span
                  className="p-inputgroup-addon"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label style={{ fontSize: 12 }}>{"Max "}</label>
                  <label style={{ fontSize: 12 }}>
                    {godBrokerage.max_fo_brokerage}
                  </label>
                </span>
              </div>
            </BalanceContainer> */}
          </BalanceWrapper>
          <BalanceUpdateButton active onClick={edit_super_brokerage}>
            Save
          </BalanceUpdateButton>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
