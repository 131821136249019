import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import moment from "moment";
import { Calendar } from "primereact/calendar";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { BalanceLabel, BalanceTextArea } from "../../Users/Add/AddUser";
import { FlexItem } from "../../Users/Overview/Styled";
import { BalanceInput } from "../../Users/Deposit/Deposit";

const php = new Php();

export default function AddNotificationModal(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();
  const [server, setServer] = React.useState("GOD");
  const [content, setContent] = React.useState("");
  const [header, setHeader] = React.useState("");
  const [date, setDate] = React.useState(
    moment(new Date()).add(1, "days").toDate()
  );

  const addRule = () => {
    if (content == null || content == "" || content == undefined) {
      window.alert("Content Must Not Be Empty", "error");
    } else if (server == null || server == "" || server == undefined) {
      window.alert("Server Must Not Be Empty", "error");
    } else if (header == null || header == "" || header == undefined) {
      window.alert("Header Must Not Be Empty", "error");
    } else if (content.length > 250) {
      window.alert("Content Must Be Only 250 Character", "error");
    } else if (header.length > 50) {
      window.alert("Header Must Be Only 50 Character", "error");
    } else {
      if (!loading) {
        setLoading(true);

        let sendData = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          body: content,
          header: header,
          server: server,
          expiry: date,
        };
        php.add_god_notification(sendData).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            props.setVisible(false);
            props.reload();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="danger"
        onClick={() => props.setVisible(false)}
      />
      <Button
        type="button"
        label="Add"
        icon="pi pi-plus"
        onClick={() => addRule()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={<div style={{ color: NewTheme.MainColor }}>Add Notification</div>}
      visible={props.visible}
      position={props.position}
      style={{ width: "40vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BalanceLabel>Server</BalanceLabel>
      </FlexItem>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BalanceInput
          value={server}
          onChange={(e) => setServer(e.target.value)}
          placeholder="Enter Server"
          style={{
            width: "100%",
          }}
          autoFocus
        />
      </div>
      <FlexItem style={{ marginTop: 20 }}>
        <BalanceLabel>Header</BalanceLabel>
      </FlexItem>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BalanceTextArea
          value={header}
          onChange={(e) => setHeader(e.target.value)}
          placeholder="Enter Notification Upto 30 Character"
          style={{
            width: "100%",
          }}
          autoFocus
        />
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BalanceLabel>Content</BalanceLabel>
      </FlexItem>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BalanceTextArea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Enter Notification Upto 150 Character"
          style={{
            width: "100%",
          }}
          autoFocus
        />
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BalanceLabel>Last Date To Show</BalanceLabel>
      </FlexItem>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Calendar
          style={{
            width: "100%",
          }}
          minDate={new Date()}
          selectionMode="single"
          id="notify"
          dateFormat="dd/mm/yy"
          value={date}
          onChange={(e) => setDate(e.value)}
        />
      </div>
    </Dialog>
  );
}
