import * as ExcelJS from "exceljs";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import TopbarMost from "../../Common/TopbarMost";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import jsPDF from "jspdf";
import "jspdf-autotable";
import NumberFormatIn from "../../Common/NumberFormatIn";

const php = new Php();
const height = window.innerHeight;

export default function Summary() {
  const [loading, setLoading] = React.useState(false);
  const [summary, setSummary] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_masters_summary();
  }, []);

  const load_masters_summary = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.load_masters_summary(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setSummary(r.masters_summary);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = ["server", "username", "margin", "pl_margin", "pl"];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Server",
      "Username",
      "Margin",
      "Net Profit/Loss",
      "Total Profit/Loss",
    ];

    const headerRow = worksheet
      .addRow([
        "MASTERS SUMMARY (S)" +
          localStorage.getItem("server").toUpperCase() +
          " (A)" +
          localStorage.getItem("adminsUsername")?.toUpperCase(),
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:E${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    const headerDisplayNamesRow = worksheet.addRow(headerDisplayNames);

    // Make the header row bold
    headerDisplayNamesRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Extract only the desired columns from transactionData
    const filteredData = summary.map((item) => {
      return colsToExport.reduce((acc, col) => {
        acc[col] = item[col];
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    // Apply color to font based on the "profit_loss" condition
    const colIndex = colsToExport.indexOf("pl_margin");
    const marginIndex = colsToExport.indexOf("margin");
    const depositIndex = colsToExport.indexOf("pl");
    const usernameIndex = colsToExport.indexOf("username"); // Add this line

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const plValue = row.getCell(colIndex + 1).value; // "pl" column value
      const marginValue = row.getCell(marginIndex + 1).value;
      const depositValue = row.getCell(depositIndex + 1).value; // "deposit" column value
      const usernameValue = row.getCell(usernameIndex + 1).value; // "username" column value

      // Format for "profit_loss" column
      if (plValue !== null && rowNumber > 4) {
        const plColor =
          plValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(colIndex + 1).font = {
          color: { argb: plColor },
          bold: true, // Make the font bold
        };

        // Convert "profit_loss" to number and set number format
        row.getCell(colIndex + 1).value =
          rowNumber > 4 ? Number(plValue) : plValue;
        row.getCell(colIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      if (marginValue !== null && rowNumber > 4) {
        row.getCell(marginIndex + 1).font = {
          bold: true, // Make the font bold
        };

        // Convert "margin" to number and set number format
        row.getCell(marginIndex + 1).value =
          rowNumber > 4 ? Number(marginValue) : marginValue;
        row.getCell(marginIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "deposit" column
      if (depositValue !== null && rowNumber > 4) {
        const depositColor =
          depositValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(depositIndex + 1).font = {
          color: { argb: depositColor },
          bold: true, // Make the font bold
        };

        // Convert "deposit" to number and set number format
        row.getCell(depositIndex + 1).value =
          rowNumber > 4 ? Number(depositValue) : depositValue;
        row.getCell(depositIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "username" column
      if (usernameValue !== null && rowNumber > 4) {
        row.getCell(usernameIndex + 1).font = {
          color: { argb: NewTheme.MainColorExcel }, // Set the font color for the "username" column
          bold: true, // Make the font bold
        };
      }
    });

    var fileName =
      "MASTER SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (A)" +
      localStorage.getItem("godsUsername")?.toUpperCase();

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const cols = [
    { dataKey: "server", title: "Server" },
    { dataKey: "name", title: "Name" },
    { dataKey: "username", title: "Username" },
    { dataKey: "margin", title: "Margin" },
    { dataKey: "pl_margin", title: "Net Profit/Loss" },
    { dataKey: "pl", title: "Profit/Loss" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("MASTER SUMMARY", 15, 15);

    doc.setFontSize(12);
    doc.text(`Server: ${localStorage.getItem("server").toUpperCase()}`, 15, 25);

    doc.setFontSize(12);
    doc.text(
      `Master: ${localStorage.getItem("godsUsername").toUpperCase()}`,
      55,
      25
    );

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 35; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, summary, { startY: tableStartY, headerStyles });

    var pdfName =
      "MASTER SUMMARY (S)" +
      localStorage.getItem("server").toUpperCase() +
      " (A)" +
      localStorage.getItem("adminsUsername")?.toUpperCase();

    doc.save(pdfName + ".pdf");
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          label="XLS"
          severity="primary"
          style={{
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  return (
    <div className="card" style={{ backgroundColor: "#ffffff" }}>
      <TopbarMost
        height={30}
        onlyHeader
        cmp={filterbar}
        name={"MASTERS SUMMARY"}
      />
      <DataTable
        removableSort
        stripedRows
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        showGridlines
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        scrollHeight={height - 200}
        scrollable
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        // filters={filters}
        value={summary}
        size="small"
      >
        <Column
          style={{ width: "15%" }}
          showFilterMenu={false}
          field="server"
          header="Server"
          sortable
        ></Column>
        <Column
          style={{ width: "15%" }}
          showFilterMenu={false}
          field="username"
          header="Master"
          sortable
        ></Column>
        <Column
          style={{ width: "15%" }}
          showFilterMenu={false}
          field="name"
          header="Name"
          sortable
        ></Column>
        <Column
          style={{ width: "15%" }}
          showFilterMenu={false}
          field="margin"
          header="Margin"
          sortable
        ></Column>

        <Column
          style={{
            width: "20%",
          }}
          sortable
          showFilterMenu={false}
          field="pl_margin"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.pl_margin >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.pl_margin} />
            </span>
          )}
          header="Net Profit/Loss"
        ></Column>

        <Column
          style={{
            width: "20%",
          }}
          sortable
          showFilterMenu={false}
          field="pl"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.pl >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={rowData.pl} />
            </span>
          )}
          header="Profit/Loss"
        ></Column>
      </DataTable>
    </div>
  );
}
