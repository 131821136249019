import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";

const php = new Php();
const height = window.innerHeight;

export default function LedgerLogs(props) {
  const [loading, setLoading] = React.useState(false);
  const [clientLedgerLogs, setClientLedgerLogs] = React.useState([]);
  const { addNotification } = useNotification();

  React.useEffect(() => {
    load_admins_ledger();
  }, []);

  const load_admins_ledger = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.id,
      };

      php.load_admins_ledger(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientLedgerLogs(r.ledger_logs);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  return (
    <div className="card">
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={clientLedgerLogs}
        size="small"
      >
        <Column
          style={{ width: "20%" }}
          sortable
          showFilterMenu={false}
          field="remarks"
          header="Remarks"
        ></Column>

        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="type"
          body={(rowData) =>
            rowData.in_out == 0 ? (
              <div style={{ color: NewTheme.greencolor }}>Deposit</div>
            ) : (
              <div style={{ color: NewTheme.redcolor }}>Withdraw</div>
            )
          }
          header="Deposit/Withdraw"
        ></Column>
        <Column
          style={{ width: "20%" }}
          showFilterMenu={false}
          field="amount"
          body={(rowData) =>
            rowData.amount >= 0 ? (
              <div style={{ color: NewTheme.greencolor }}>
                +{rowData.amount}
              </div>
            ) : (
              <div style={{ color: NewTheme.redcolor }}>{rowData.amount}</div>
            )
          }
          header="Amount"
        ></Column>
        {/* <Column
          style={{ width: "30%" }}
          showFilterMenu={false}
          field="type"
          body={(rowData) => (
            <div>
              {rowData.old_balance}
              <span
                style={{
                  color: NewTheme.MainColor,
                  fontWeight: "bolder",
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                {"  ->  "}
              </span>
              {rowData.new_balance}
            </div>
          )}
          header="Old->New Bal"
        ></Column> */}
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="date_created"
          header="Date/Time"
        ></Column>
      </DataTable>
    </div>
  );
}
