import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import Php from "../../../Backend/Php";
import { NewTheme } from "../../Theme/Theme";
import {
  BoldText,
  CreateClientButton,
  FlexItem,
} from "../../Users/Overview/Styled";
import { InputText } from "primereact/inputtext";

const php = new Php();
const height = window.innerHeight;

export default function UploadMcxRates(props) {
  const [loading, setLoading] = React.useState(false);
  const [parkingData, setParkingData] = React.useState([]);

  React.useEffect(() => {
    get_mcx_closing();
  }, []);

  const get_mcx_closing = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.get_mcx_closing(data).then((r) => {
        console.log(r);
        setLoading(false);
        if (r.error === "False") {
          setParkingData(r.mcx_data);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const update_mcx_rate = (mcx) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        script_id: mcx?.script_id,
        symbol: mcx?.symbol,
        rate: parseFloat(mcx?.current_rate),
      };

      php.update_mcx_rate(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const handleInputChange = (value, index) => {
    const updatedParkingData = [...parkingData]; // Creating a copy of the parkingData array
    updatedParkingData[index].current_rate = value; // Updating the current_rate of the corresponding item
    setParkingData(updatedParkingData); // Updating the state with the modified array
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>{"Upload Mcx Rates"}</div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "70vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
      loading={loading}
    >
      {parkingData.map((i, t) => (
        <>
          <FlexItem
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <BoldText style={{ width: "50%" }}>
              {i.symbol}{" "}
              <span style={{ color: NewTheme.MainColor }}>
                {moment(i.expiry_date).format("DD-MMM-YYYY ")}
              </span>
            </BoldText>

            <InputText
              type="number"
              value={i.current_rate} // Binding input value to current_rate
              onChange={(e) => handleInputChange(e.target.value, t)} // Calling handleInputChange on change
              placeholder="Enter Rate"
            />
            <CreateClientButton onClick={() => update_mcx_rate(i)}>
              Update
            </CreateClientButton>
          </FlexItem>
        </>
      ))}
    </Dialog>
  );
}
