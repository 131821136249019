import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { BalanceInput, BalanceLabel } from "../../Users/Deposit/Deposit";
import { FlexItem } from "./Styled";

const php = new Php();

export default function CFMarginModal(props) {
  const [formData, setFormData] = React.useState(
    props.marginArray
      ? props.marginArray
      : {
          fut_cf_margin: "60",
          mcx_cf_margin: "60",
          fo_cf_margin: "60",
          forex_cf_margin: "60",
          crypto_cf_margin: "60",
          stocks_cf_margin: "60",
        }
  );

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  function handleAdminMargin() {
    for (const key in formData) {
      const value = formData[key];
      // Check if the value is less than or equal to 0
      if (value <= 0) {
        // Trigger an error notification specifying which margin is incorrect
        addNotification(
          `Invalid value for ${key.replace(
            /_/g,
            " "
          )}: must be greater than 0.`,
          "error"
        );
        return; // Stop further processing if any value is invalid
      }
    }

    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.admin_id,
      };
      Object.keys(formData).forEach((key) => {
        const newKey = `${key}`; // Create a new key name
        data[newKey] = formData[key]; // Assign the value from formData to this new key in data
      });

      php.change_admins_margin(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          props.load_admin_overview();
          props.setVisible();
          alert(r.message);
        } else {
          alert(r.message);
        }
      });
    }
  }

  const formatLabel = (key) => {
    return key
      .replace(/_/g, " ")
      .replace("fut", "Future")
      .replace("fo", "Option")
      .toUpperCase(); // Transform text to uppercase
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        label="Update"
        icon="pi pi-check"
        onClick={() => handleAdminMargin()}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );

  return (
    <Dialog
      header={<div style={{ color: NewTheme.MainColor }}>Edit CF Margin </div>}
      visible={props.visible}
      position={props.position}
      style={{ width: "55vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      {Object.entries(formData).map(([key, value], index) => (
        <div
          key={index}
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FlexItem style={{ marginTop: 20 }}>
            <BalanceLabel style={{ textTransform: "uppercase" }}>
              {formatLabel(key)}
            </BalanceLabel>
          </FlexItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BalanceInput
              type="text"
              value={value}
              onChange={(e) => handleChange(key, e.target.value)}
              placeholder={`Enter ${key.replace(/_/g, " ")}`}
              style={{
                width: "100%",
                paddingLeft: 10,
              }}
              autoFocus={index === 0} // Only the first input should be auto-focused
            />
          </div>
        </div>
      ))}
    </Dialog>
  );
}
