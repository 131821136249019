import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import Php from "../../../Backend/Php";
import { NewTheme } from "../../Theme/Theme";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

const php = new Php();
const height = window.innerHeight;

export default function CheckRatesModal(props) {
  const [loading, setLoading] = React.useState(false);
  const [parkingData, setParkingData] = React.useState([]);

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  React.useEffect(() => {
    get_position_rates();
  }, []);

  const get_position_rates = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.get_position_rates(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setParkingData(r.position_data);
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Check Rates "}
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <InputText
              style={{ width: 140, height: 30 }}
              type="search"
              id="search2"
              value={value || ""}
              onChange={(e) => onGlobalFilterChange(e)}
              placeholder="Search"
            />
          </div>
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "70vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
      loading={loading}
    >
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollHeight={height - 200}
        scrollable
        // filters={filters}
        value={parkingData}
        size="small"
        globalFilterFields={["symbol"]}
        paginator
        rows={10}
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        rowsPerPageOptions={[10, 30, 50, 100]}
        loading={loading}
      >
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="symbol"
          header="symbol"
          body={(rowData) => (
            <>
              <div
                style={{
                  color: NewTheme.MainColor,
                }}
              >
                {rowData.symbol}
              </div>
            </>
          )}
        ></Column>
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="symbol"
          header="symbol"
          body={(rowData) => (
            <>
              <span style={{ color: "gray" }}>
                {moment(rowData.expiry_date).format("DD-MMM-YY")}
              </span>
            </>
          )}
        ></Column>
        <Column
          style={{ width: "10%" }}
          sortable
          showFilterMenu={false}
          field="current_rate"
          header="Closing Rate"
        ></Column>
      </DataTable>
    </Dialog>
  );
}
