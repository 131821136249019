import React, { useEffect } from "react";
import styled from "styled-components";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import AddMaster from "./Add/AddMaster";
import MastersList from "./List/MastersList";
import RightSide from "./RightSide";
import Summary from "./Summary/Summary";

const php = new Php();

const DashboardContainer = styled.div`
  display: flex;
  height: calc(
    100vh - 60px
  ); /* Subtract the top menu bar height from the viewport height */
`;

const LeftPanel = styled.div`
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-right: 1px;
  min-width: 300px;
  border-right: 1px solid gray; /* Add a 1px gray border at the right of the tabs */

  &::-webkit-scrollbar {
    width: 1px; /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Set the background color of the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${NewTheme.MainColor}; /* Set the color of the scrollbar thumb */
    border: none; /* Remove the border */
    border-radius: 4px; /* Add some border-radius for a rounded look */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${NewTheme.MainColor}; /* Change color on hover */
  }
`;

const TopBar = styled.div`
  background-color: ${NewTheme.lightmain};
  color: ${NewTheme.MainColor};
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2; /* Ensure it's above the scrolling content */
`;

const SearchBar = styled.div`
  background-color: ${NewTheme.lightmain};
  color: ${NewTheme.MainColor};
  padding: 5px 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
  border-bottom: 2px solid ${NewTheme.MainColor};
  padding-bottom: 10px;
  position: sticky;
  top: 50px; /* Adjust this value based on your design */
  z-index: 2;
`;

const TopBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;

const RightPanel = styled.div`
  flex: 3; /* Takes up 3/4 of the width */
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  max-height: 100%; /* Set the maximum height to 100% of the adjusted viewport height */
`;

const CreateClientButton = styled.button`
  background-color: ${(props) =>
    props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
  color: ${(props) => (props.greencolor ? "#ffffff" : NewTheme.lightmain)};
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Add smooth transitions */

  &:hover {
    background-color: #ffffff;
    color: ${(props) =>
      props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
    border: 1px solid
      ${(props) =>
        props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
  }
`;

const Heading = styled.h4`
  font-size: 16px;
`;

const SearchInput = styled.input`
  border: 1px solid ${NewTheme.MainColor};
  background-color: ${NewTheme.lightmain};
  color: ${NewTheme.MainColor};
  padding: 10px 10px;
  width: 100%;
  outline: none;
  border-radius: 5px;
`;

function Users() {
  const { addNotification } = useNotification();
  const [clients, setUsers] = React.useState([]);
  const [clientsFilter, setUsersFilter] = React.useState([]);
  const [createClientButtonToggle, setCreateClientButtonToggle] =
    React.useState(0);

  const [selectedclient, setSelectedClient] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const openRef = React.useRef();

  useEffect(() => {
    loadMasters();
  }, []);

  const loadMasters = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };
      php.load_masters(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setUsers(r.users);
          setUsersFilter(r.users);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleCreateClient = () => {
    setCreateClientButtonToggle(1);
  };

  const handleSummaryButton = () => {
    setCreateClientButtonToggle(0);
  };

  const handleUserClick = () => {
    if (selectedclient != "") {
      setCreateClientButtonToggle(2);
    }
  };

  const searchUsers = (value) => {
    var username = value;
    if (username.length > 1) {
      let script = [];

      script = clientsFilter.filter((x) =>
        x.username.toLowerCase().includes(username.toLowerCase())
      );
      setUsers(script);
    } else {
      setUsers(clientsFilter);
    }
  };

  const topworkui = (
    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
      {/* <CreateClientButton
        greencolor
        style={{
          height: 30,
          marginLeft: 10,
        }}
        onClick={handleCreateClient}
      >
        + Create Master
      </CreateClientButton> */}
      <CreateClientButton
        style={{ height: 30, marginLeft: 10 }}
        onClick={handleSummaryButton}
      >
        Masters Summary
      </CreateClientButton>
      <CreateClientButton
        style={{ height: 30, marginLeft: 10 }}
        onClick={handleUserClick}
      >
        {selectedclient != ""
          ? "Master : " + selectedclient?.username?.toUpperCase()
          : "N/A"}
      </CreateClientButton>
    </div>
  );

  return (
    <>
      <TopbarMost name={"MASTERS"} cmp={topworkui} />
      <DashboardContainer>
        <LeftPanel>
          <TopBarContainer>
            <SearchBar>
              <SearchInput
                onChange={(e) => searchUsers(e.target.value)}
                type="text"
                placeholder="Search for a master"
              />
            </SearchBar>
          </TopBarContainer>
          <MastersList
            selected={selectedclient}
            select={(a) => {
              setSelectedClient(a);
              setCreateClientButtonToggle(2);
              setTimeout(() => {
                openRef.current();
              }, 20);
            }}
            clients={clients}
          />
        </LeftPanel>
        <RightPanel>
          {createClientButtonToggle == 0 ? (
            <Summary />
          ) : createClientButtonToggle == 1 ? (
            <AddMaster
              reload={() => loadMasters()}
              close={() => setCreateClientButtonToggle(0)}
            />
          ) : (
            <RightSide
              reload={() => loadMasters()}
              openRef={openRef}
              data={selectedclient}
            />
          )}
        </RightPanel>
      </DashboardContainer>
    </>
  );
}

export default Users;
