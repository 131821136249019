import React from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export const BalanceUpdateButton = styled.div`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function AddAdmin(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [name, setName] = React.useState("");
  const [server, setServer] = React.useState("");
  const [username, setUserame] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [margin, setMargin] = React.useState(0);

  const godMargin = JSON.parse(localStorage.getItem("godsDetails")).margin;

  const add_admin = (e) => {
    if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (server == "" || server.length > 8 || server.length < 2) {
      addNotification("Please Enter Server From 2 to 8 Character", "error");
    } else if (username == "" || username.length > 8 || username.length < 2) {
      addNotification("Please Enter Username From 2 to 8 Character", "error");
    } else if (password == "" || password.length < 3) {
      addNotification(
        "Please Enter Password with atleast 3 character",
        "error"
      );
    } else if (margin < 0 || parseFloat(margin) > parseFloat(godMargin)) {
      addNotification("Please Enter Margin Between 0 to " + godMargin, "error");
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          name: name,
          server: server,
          username: username,
          password: password,
          margin: margin,
        };

        php.add_admin(data).then((r) => {
          console.log(r);
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            props.reload();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const calcSharing = (value) => {
    if (parseFloat(value) > parseFloat(godMargin) || godMargin < 0) {
      setMargin("");
      addNotification(
        "Sharing Must Be Between 0 To " + parseFloat(godMargin).toFixed(0),
        "error"
      );
      // } else if (sharing < 1 && value > 0) {
      //   setSharing(0);
      //   alert.error("Sharing Must Be Between 0 To " + sharing);
    } else {
      setMargin(value);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      {/* <Patti /> */}
      <Card
        title="Add Admin"
        style={{
          margin: 10,
          position: "relative",
          height: "calc(100% - (60px - 1px))",
          overflowY: "scroll",
        }}
      >
        <CreateClientButton
          active={false}
          color={NewTheme.redcolor}
          onClick={() => props.close()}
          style={{ position: "absolute", top: 10, right: 5 }}
        >
          Close
        </CreateClientButton>
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Server </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setServer(e.target.value)}
                value={server}
                placeholder="Enter Server "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel> Name </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Enter Name "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Username </BalanceLabel>
              <BalanceInput
                type="text"
                placeholder="Enter Username 2 to 14 Character "
                onChange={(e) => setUserame(e.target.value)}
                value={username}
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Password</BalanceLabel>
              <BalanceInput
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password with atleast 3 character"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Admin Margin</BalanceLabel>
              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <InputText
                  type="number"
                  value={margin}
                  onChange={(e) => calcSharing(e.target.value)}
                  placeholder="Enter "
                />

                <span className="p-inputgroup-addon">
                  {parseFloat(godMargin).toFixed(0) - margin}
                </span>
              </div>
            </BalanceContainer>
          </BalanceWrapper>
          <BalanceUpdateButton onClick={add_admin}>Save</BalanceUpdateButton>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
