import React from "react";
// import { useNotification } from "../../Notification/NotificationContext";
import Php from "../../../Backend/Php";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { NewTheme } from "../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();
const height = window.innerHeight;

const CreateClientButton = styled.button`
  background-color: ${NewTheme.MainColor};
  color: ${NewTheme.lightmain};
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid ${NewTheme.MainColor};
  transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Add smooth transitions */

  &:hover {
    background-color: #fff;
    color: ${NewTheme.MainColor};
    border: 1px solid ${NewTheme.MainColor};
  }
`;

const SearchInput = styled.input`
  border: 1px solid ${NewTheme.MainColor};
  background-color: ${NewTheme.lightmain};
  color: ${NewTheme.MainColor};
  padding: 10px 10px;
  width: 100%;
  outline: none;
  border-radius: 5px;
`;

export default function Script(props) {
  const [loading, setLoading] = React.useState(false);
  const [scriptName, setfilteredValue] = React.useState("");
  const [clientScript, setClientScript] = React.useState([]);
  const [clientFutScript, setClientFutScript] = React.useState([]);
  const [clientMcxScript, setClientMcxScript] = React.useState([]);
  const [clientFoScript, setClientFoScript] = React.useState([]);
  const [clientBannedScript, setClientBannedScript] = React.useState([]);
  const { addNotification } = useNotification();

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  React.useEffect(() => {
    load_admins_scripts();
  }, []);

  const load_admins_scripts = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.id,
      };

      php.load_admins_scripts(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientScript(r.script);
          /////

          if (scriptName.length < 2) {
            let fut = r.script.filter((x) => x.script_type == "fut");
            setClientFutScript(fut);
          } else {
            searchScript(scriptName);
          }
          let mcx = r.script.filter((x) => x.script_type == "mcx");
          let fo = r.script.filter((x) => x.script_type == "fo");

          setClientMcxScript(mcx);
          setClientFoScript(fo);
          setClientBannedScript(r.banned);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const totalQtyHandler = (value, rowData) => {
    var new_data = clientScript;
    var new_row = rowData;

    if (!isNaN(parseFloat(value))) {
      new_row.total_qty = parseFloat(value);
    } else {
      new_row.total_qty = "0";
    }
    setClientScript([...clientScript, new_row]);
  };

  const punchQtyHandler = (value, rowData) => {
    var new_data = clientScript;
    var new_row = rowData;
    if (!isNaN(parseFloat(value))) {
      new_row.punch_qty = parseFloat(value);
    } else {
      new_row.punch_qty = "0";
    }
    setClientScript([...clientScript, new_row]);
  };

  const handleEditQty = (rowData) => {
    if (
      rowData.symbol == "" ||
      rowData.symbol == null ||
      rowData.symbol == undefined
    ) {
      addNotification("Symbol Not Selected", "error");
    } else if (rowData.punch_qty <= 0 || rowData.punch_qty == "") {
      alert.error("Invalid Punch Qty");
    } else if (rowData.total_qty <= 0 || rowData.total_qty == "") {
      alert.error("Invalid Total Qty");
    } else if (rowData.script_type == "fo") {
      alert.error("You Cannot Edit Options Scripts");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          s_id: localStorage.getItem("supersId"),
          c_id: props.data.id,
          symbol: rowData.symbol,
          s_type: rowData.script_type,
          punch_qty: rowData.punch_qty,
          total_qty: rowData.total_qty,
        };

        php.edit_users_scripts(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            load_admins_scripts();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const handleBanScript = (rowData) => {
    if (rowData.script_type == "fo") {
      alert.error("You Cannot Edit Options Scripts");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          m_id: props.data.id,
          symbol: rowData.symbol,
        };
        php
          .ban_masters_script(data)
          .then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_admins_scripts();
              addNotification(r.message, "success");
            } else {
              addNotification(r.message, "error");
            }
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      }
    }
  };

  const handleUnbanScript = (rowData) => {
    if (rowData.script_type == "fo") {
      alert.error("You Cannot Edit Options Scripts");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          s_id: props.data.id,
          symbol: rowData.symbol,
        };

        php
          .unban_supers_script(data)
          .then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_admins_scripts();
              addNotification(r.message, "success");
            } else {
              addNotification(r.message, "error");
            }
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      }
    }
  };

  const handleResetScript = (rowData) => {
    if (rowData.script_type == "fo") {
      alert.error("You Cannot Edit Options Scripts");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          s_id: localStorage.getItem("supersId"),
          c_id: props.data.id,
          symbol: rowData.symbol,
        };

        php.reset_users_scripts(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            load_admins_scripts();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const searchScript = (value) => {
    var scriptName = value;
    if (scriptName.length > 2) {
      let scriptData = [];

      scriptData = clientScript.filter(
        (x) =>
          x.symbol.toLowerCase().includes(scriptName.toLowerCase()) &&
          x.script_type == "fut"
      );
      setClientFutScript(scriptData);
      setfilteredValue(scriptName);
    } else {
      let fut = clientScript.filter((x) => x.script_type == "fut");
      setClientFutScript(fut);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <TabView>
        <TabPanel style={{ color: NewTheme.MainColor }} header="FUT">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              filters={filters}
              value={clientFutScript}
              size="small"
            >
              <Column
                style={{ width: "30%" }}
                sortable
                showFilterMenu={false}
                field="name"
                header="Symbol"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Punch Qty"
                body={(rowData) => (
                  <InputText
                    disabled
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.punch_qty).toFixed(0)}
                    onChange={(e) => punchQtyHandler(e.target.value, rowData)}
                  />
                )}
                field="punch_qty"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Total Qty"
                field="total_qty"
                body={(rowData) => (
                  <InputText
                    disabled
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.total_qty).toFixed(0)}
                    onChange={(e) => totalQtyHandler(e.target.value, rowData)}
                  />
                )}
              ></Column>
              <Column
                style={{ gap: 10 }}
                header="Operate"
                body={(rowData) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {/* <CreateClientButton onClick={() => handleEditQty(rowData)}>
                      {rowData.edit_qty > 0 ? "Edit" : "Save"}
                    </CreateClientButton>
                    <CreateClientButton
                      onClick={() => handleResetScript(rowData)}
                    >
                      Reset
                    </CreateClientButton> */}
                    <CreateClientButton
                      onClick={() => handleBanScript(rowData)}
                    >
                      Ban
                    </CreateClientButton>
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header="MCX">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              size="small"
              filters={filters}
              value={clientMcxScript}
            >
              <Column
                style={{ width: "30%" }}
                sortable
                showFilterMenu={false}
                field="name"
                header="Name"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Punch Qty"
                body={(rowData) => (
                  <InputText
                    disabled
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.punch_qty).toFixed(0)}
                    onChange={(e) => punchQtyHandler(e.target.value, rowData)}
                  />
                )}
                field="punch_qty"
              ></Column>
              <Column
                style={{ width: "20%" }}
                header="Total Qty"
                field="total_qty"
                body={(rowData) => (
                  <InputText
                    disabled
                    style={{ width: "100%" }}
                    value={parseFloat(rowData.total_qty).toFixed(0)}
                    onChange={(e) => totalQtyHandler(e.target.value, rowData)}
                  />
                )}
              ></Column>
              <Column
                style={{ gap: 10 }}
                header="Operate"
                body={(rowData) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {/* <CreateClientButton onClick={() => handleEditQty(rowData)}>
                      Save
                    </CreateClientButton>
                    <CreateClientButton
                      onClick={() => handleResetScript(rowData)}
                    >
                      Reset
                    </CreateClientButton> */}
                    <CreateClientButton
                      onClick={() => handleBanScript(rowData)}
                    >
                      Ban
                    </CreateClientButton>
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header="OPTIONS">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              size="small"
              filters={filters}
              value={clientFoScript}
            >
              <Column
                sortable
                showFilterMenu={false}
                field="name"
                header="Name"
                body={(rowData) => rowData.symbol + " " + rowData.type}
              ></Column>
              <Column header="Punch Qty" field="punch_qty"></Column>
              <Column header="Total Qty" field="total_qty"></Column>
            </DataTable>
          </div>
        </TabPanel>
        <TabPanel header="BANNED">
          <div className="card">
            <DataTable
              removableSort
              stripedRows
              showGridlines
              scrollHeight={height - 200}
              scrollable
              filters={filters}
              size="small"
              value={clientBannedScript}
            >
              <Column
                sortable
                showFilterMenu={false}
                field="symbol"
                header="Name"
              ></Column>
              <Column
                header="Operate"
                body={(rowData) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    {rowData.admin_ban == 1 ? (
                      <CreateClientButton
                        onClick={() => handleUnbanScript(rowData)}
                      >
                        Unban
                      </CreateClientButton>
                    ) : (
                      <CreateClientButton
                      // onClick={() => handleUnbanScript(rowData)}
                      >
                        {" BANNED FOR " + rowData?.admin_ban?.toUpperCase()}
                      </CreateClientButton>
                    )}
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </TabPanel>
      </TabView>
      <div style={{ position: "absolute", top: 10, right: 10 }}>
        <SearchInput
          onChange={(e) => searchScript(e.target.value)}
          type="text"
          placeholder="Search script"
        />
      </div>
    </div>
  );
}
